module.exports = {
  siteTitle: 'Matt Penna',
  subTitle: 'Fullstack Developer',
  resumeTitle: "Matt Penna Resume",
  blogTitle: "Blog",
  projectsTitle: "Projects",
  siteDescription: `Matt Penna developer profile and blog`,
  keyWords: ['mwpenna', 'java', 'spring', 'C#'],
  authorName: 'Matt Penna',
  githubUsername: 'mattpenna-dev',
  authorAvatar: '/images/avatar.png',
  authorDescription: `Experienced fullstack software developer with a track record for building highly available and scalable applications. `,
  skills: [
    {
      name: 'Java/Spring',
      level: 90
    },
    {
      name: 'C#',
      level: 60
    },
    {
      name: 'Cloud Development',
      level: 90
    },
    {
      name: 'Angular',
      level: 40
    },
    {
      name: 'Automated Testing',
      level: 60
    },
    {
      name: 'Agile',
      level: 70
    }
  ],
  jobs: [
    {
      company: "Insight",
      begin: {
        month: 'Aug',
        year: '2019'
      },
      duration: null,
      occupation: "Principal Software Engineer",
      description: "Fullstack developer. Feature development, bug fixes, architecture diagrams, GCP, Azure"
  
    }, {
      company: "Intradiem",
      begin: {
        month: 'May',
        year: '2018'
      },
      duration: '1 yr 4 months',
      occupation: "Sr Software engineer",
      description: "Feature development, Bug fixes, Prod support. Tech Lead."
    }, {
      company: "Cox Auto Inc",
      begin: {
        month: 'Apr',
        year: '2016'
      },
      duration: '2 yrs 2 months',
      occupation: "Team Lead/Software Engineer",
      description: "Feature development, bug fixes, automation testing, performance testing."
  
    },
    /* ... */
  ],
  social: {
    linkedin: "https://www.linkedin.com/in/matthew-penna-2b447856/",
    github: "https://github.com/mattpenna-dev",
    email: "penna.matt@yahoo.com"
  },
  siteUrl: 'https://www.mattpenna.dev',
  pathPrefix: '/gatsby-starter-cv', // Note: it must *not* have a trailing slash.
  siteCover: '/images/cover.jpeg',
  googleAnalyticsId: 'UA-167266663-1',
  background_color: '#ffffff',
  theme_color: '#25303B',
  display: 'minimal-ui',
  icon: 'src/assets/icon.png',
  headerLinks: [
    {
      label: 'Matt Penna',
      url: '/',
    },
    {
      label: 'Resume',
      url: '/resume'
    },
    {
      label: 'Projects',
      url: '/projects'
    },
    {
      label: 'Blog',
      url: '/blog'
    }
  ]
}
